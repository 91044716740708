





















































































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ControlButton extends Vue {
  @Prop() private icon!: string;
  @Prop() private disabled!: boolean;
  @Prop({ default: 1 }) private tabindex!: number;
  @Prop() private track!: PlaylistTrackInfo;

  onClick(e: Event) {
    const button = e.target as HTMLElement;

    if (button.getAttribute('aria-disabled')) {
      return;
    }

    this.$emit(e.type, e);
  }

  onKey(e: KeyboardEvent) {
    const button = e.target as HTMLElement;

    if (button.getAttribute('aria-disabled')) {
      return;
    }

    this.$emit(e.type, e, this.track);
  }
}
