function zeroPad(n: number): string {
  return n < 10 ? `0${n}` : `${n}`;
}

export default function(time: number, padMinutes?: boolean): string {
  const t = Math.abs(time);
  const minutes = Math.floor(t / 60 || 0);
  const seconds = Math.floor(t % 60 || 0);
  return `${(time < 0 ? '-' : ' ') +
    (padMinutes === true ? zeroPad(minutes) : minutes)}:${zeroPad(seconds)}`;
}
