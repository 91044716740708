


































import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import formatTime from '../util/format-time';

@Component
export default class PlaybackScrubber extends Vue {
  @Prop({ type: Number, default: 0 }) readonly current!: number;
  @Prop({ type: Number, default: 0 }) readonly buffered!: number;
  @Prop({ type: Number, default: 0 }) readonly total!: number;
  @Prop() readonly displayTimeRemaining!: boolean;
  @Prop() readonly progressBarColor!: string;
  @Ref() readonly progressBarWrapper!: HTMLDivElement;

  fast = false;

  protected previous = 0;

  get bufferBarTransform(): string {
    return `scaleX(${this.total ? Number(this.buffered || 0).toFixed(3) : 0})`;
  }

  get transportBarTransform(): string {
    if (Math.abs(this.current - this.previous) > 1) {
      this.fast = true;
      setTimeout(() => {
        this.fast = false;
      }, 250);
    }

    this.previous = this.current;
    return `scaleX(${
      this.total ? Number(this.current / this.total).toFixed(3) : 0
    })`;
  }

  get bufferBarStyle(): Dictionary {
    const style: {
      transform: string;
      backgroundColor?: string;
    } = {
      transform: this.bufferBarTransform,
    };

    if (this.progressBarColor) {
      style.backgroundColor = this.progressBarColor;
    }

    return style;
  }

  get transportBarStyle(): Dictionary {
    const style: {
      transform: string;
      backgroundColor?: string;
    } = {
      transform: this.transportBarTransform,
    };

    if (this.progressBarColor) {
      style.backgroundColor = this.progressBarColor;
    }

    return style;
  }

  get remaining() {
    return this.current - this.total;
  }

  formatTime(time: number): string {
    return formatTime(time, true);
  }

  onSeek(e: MouseEvent) {
    const {
      width,
      x,
      left,
    } = (this.progressBarWrapper.getBoundingClientRect() as unknown) as DOMRect;

    this.$emit('seek', (e.pageX - (x ? x : left)) / width);
    this.fast = true;
  }
}
